.table {
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.fullWidth {
  width: 100%;
}

.caption {
  color: var(--sds-color-text-secondary);
}
