.Course_courseTitle__E3xjc {
  margin-left: 0.8rem;
  margin-top: 3rem;
}

.Course_courseTableContainer__PbU8l {
  margin-top: 1rem;
}

.Breadcrumbs_breadcrumbs__DGlh4 {
  margin: var(--sds-space-padding-small) 0 var(--sds-space-padding-medium) 0;
}

.PageArrangement_container__PkT9m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: var(--sds-space-padding-medium) var(--sds-space-padding-medium);
}

.PageArrangement_content__DzSNN {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  width: 100%;
}

.PageArrangement_children__mWjUK {
  min-height: 10rem;
}

.PageArrangement_backButton__Olqnc {
  margin: var(--sds-space-padding-small) 0 var(--sds-space-padding-medium) 0;
}

@keyframes Skeleton_pulse__HZXEU {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }
  .Skeleton_skeleton__wtgPk {

  background: linear-gradient(90deg, #909090 0%, #dcdcdc 25%, #eaeaea 65%, #909090 100%);
  opacity: 0.4;
  background-size: 200% 100%;
  animation: Skeleton_pulse__HZXEU 1.5s infinite;
}
  .Skeleton_skeleton__wtgPk.Skeleton_radius-minimal__C0ucQ {
      border-radius: var(--sds-space-border-radius-minimal);
    }
  .Skeleton_skeleton__wtgPk.Skeleton_radius-small__pf1Q4 {
      border-radius: var(--sds-space-border-radius-small);
    }
  .Skeleton_skeleton__wtgPk.Skeleton_radius-medium__4GxDP {
      border-radius: var(--sds-space-border-radius-medium);
    }
  .Skeleton_skeleton__wtgPk.Skeleton_radius-full__dYABV {
      border-radius: var(--sds-space-border-radius-full);
    }

.Flex_flex__PiSo7 {
  display: flex;
}

  .Flex_flex__PiSo7.Flex_fullWidth__9O_xd {
    width: 100%;
  }

  .Flex_flex__PiSo7.Flex_align-center__UGdbT {
      align-items: center;
    }

  .Flex_flex__PiSo7.Flex_align-baseline__OXc4H {
      align-items: baseline;
    }

  .Flex_flex__PiSo7.Flex_align-end__e496_ {
      align-items: end;
    }

  .Flex_flex__PiSo7.Flex_align-flex-end__hYbSL {
      align-items: flex-end;
    }

  .Flex_flex__PiSo7.Flex_align-flex-start__Xx0lZ {
      align-items: flex-start;
    }

  .Flex_flex__PiSo7.Flex_align-self-end__Oi_Yg {
      align-items: self-end;
    }

  .Flex_flex__PiSo7.Flex_align-self-start__cB2mK {
      align-items: self-start;
    }

  .Flex_flex__PiSo7.Flex_align-start__7oAAq {
      align-items: start;
    }

  .Flex_flex__PiSo7.Flex_justify-space-around__Mre3i {
      justify-content: space-around;
    }

  .Flex_flex__PiSo7.Flex_justify-space-between__VqE_y {
      justify-content: space-between;
    }

  .Flex_flex__PiSo7.Flex_justify-space-evenly__9RC_9 {
      justify-content: space-evenly;
    }

  .Flex_flex__PiSo7.Flex_justify-stretch__SUudo {
      justify-content: stretch;
    }

  .Flex_flex__PiSo7.Flex_justify-flex-end__vppQo {
      justify-content: flex-end;
    }

  .Flex_flex__PiSo7.Flex_gap-minimal__sLixx {
      gap: var(--sds-space-gap-minimal);
    }

  .Flex_flex__PiSo7.Flex_gap-tiny___mwmg {
      gap: var(--sds-space-gap-tiny);
    }

  .Flex_flex__PiSo7.Flex_gap-small__7oDSF {
      gap: var(--sds-space-gap-small);
    }

  .Flex_flex__PiSo7.Flex_gap-medium__Nidp1 {
      gap: var(--sds-space-gap-medium);
    }

  .Flex_flex__PiSo7.Flex_gap-large__ZVS88 {
      gap: var(--sds-space-gap-large);
    }

  .Flex_flex__PiSo7.Flex_gapColumn-minimal__htl_l {
      column-gap: var(--sds-space-gap-minimal);
    }

  .Flex_flex__PiSo7.Flex_gapColumn-tiny__gX6ic {
      column-gap: var(--sds-space-gap-tiny);
    }

  .Flex_flex__PiSo7.Flex_gapColumn-small__4HpNv {
      column-gap: var(--sds-space-gap-small);
    }

  .Flex_flex__PiSo7.Flex_gapColumn-medium__7MuYI {
      column-gap: var(--sds-space-gap-medium);
    }

  .Flex_flex__PiSo7.Flex_gapColumn-large__Ka9Nv {
      column-gap: var(--sds-space-gap-large);
    }

  .Flex_flex__PiSo7.Flex_gapRow-minimal__xzYaa {
      row-gap: var(--sds-space-gap-minimal);
    }

  .Flex_flex__PiSo7.Flex_gapRow-tiny__kGp4E {
      row-gap: var(--sds-space-gap-tiny);
    }

  .Flex_flex__PiSo7.Flex_gapRow-small__SAFDv {
      row-gap: var(--sds-space-gap-small);
    }

  .Flex_flex__PiSo7.Flex_gapRow-medium__qaA7o {
      row-gap: var(--sds-space-gap-medium);
    }

  .Flex_flex__PiSo7.Flex_gapRow-large__0VSQl {
      row-gap: var(--sds-space-gap-large);
    }

  .Flex_flex__PiSo7.Flex_wrap-wrap__EyopX {
      flex-wrap: wrap;
    }

  .Flex_flex__PiSo7.Flex_wrap-nowrap__8CJ_T {
      flex-wrap: nowrap;
    }

  .Flex_flex__PiSo7.Flex_wrap-wrap-reverse__e260P {
      flex-wrap: wrap-reverse;
    }

  .Flex_flex__PiSo7.Flex_flex-column__ak1qb {
      flex-direction: column;
    }

  .Flex_flex__PiSo7.Flex_flex-column-reverse__06eAD {
      flex-direction: column-reverse;
    }

  .Flex_flex__PiSo7.Flex_flex-row__EoAFa {
      flex-direction: row;
    }

  .Flex_flex__PiSo7.Flex_flex-row-reverse__TsADB {
      flex-direction: row-reverse;
    }

  .Flex_flex__PiSo7.Flex_grow-0___jkOr {
    flex-grow: 0;
  }

  .Flex_flex__PiSo7.Flex_grow-1__9KrPp {
    flex-grow: 1;
  }

  .Flex_flex__PiSo7.Flex_grow-2__bciA1 {
    flex-grow: 2;
  }

.PageLayout_container__Ve6R5 {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: var(--sds-space-padding-medium) var(--sds-space-padding-medium);
}

  .PageLayout_container__Ve6R5 .sds-drawer {
    height: unset;
  }
.PageLayout_content__LanfI {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  width: 100%;
  flex: 1;
}
.PageLayout_children__yFbsr {
  min-height: 10rem;
}
.PageLayout_breadcrumbs__yemLc {
  margin: var(--sds-space-padding-small) 0 var(--sds-space-padding-medium) 0;
}

.PageArrangementCenterSplit_container__nodf6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1.4rem;
}

.PageArrangementCenterSplit_left__9eHE_ {
  width: 100%;
}

.PageArrangementCenterSplit_right__Ig6rs {
  width: 100%;
}

@media (width <= 1440px) {
  .PageArrangementCenterSplit_container__nodf6 {
    flex-direction: column;
  }

    .PageArrangementCenterSplit_container__nodf6 .PageArrangementCenterSplit_left__9eHE_ {
      min-width: 100%;
    }
}

.Term_termTitle__6Y4T1 {
  margin-top: 2rem;
  margin-left: 0.8rem;
}

.FSTableColumnContent_cell__ltVTE {
  color: var(--sds-color-text-secondary);
}

.FSTableColumnContent_left__ygjeU {
  display: flex;
  justify-content: flex-start;
}

.FSTableColumnContent_right__N7esg {
  display: flex;
  justify-content: flex-end;
}

.FSTableColumnContent_center__hAO49 {
  display: flex;
  justify-content: center;
}

.FSTableColumn_padding__TSLsY {
  padding: var(--sds-space-padding-small);
}

.FSTableColumn_primaryColor__XU4a5 {
  background-color: var(--sds-color-layout-background-primary);
}

.FSTableColumn_infoColor__p3irW {
  background-color: var(--sds-color-layout-background-info);
}

.FSTableColumn_whiteColor__SF_my {
  background-color: var(--sds-color-layout-background-default);
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.FSTableColumn_roundedTopLeft__JG9J7 {
  border-radius: var(--sds-space-border-radius-medium) 0 0 0;
}

.FSTableColumn_roundedTopRight__J3df8 {
  border-radius: 0 var(--sds-space-border-radius-medium) 0 0;
}

.FSTableColumn_borderRight__8icpY {
  border-right: 1px solid var(--sds-color-layout-page-default);
}

.FSTableColumn_borderLeft__BWKE5 {
  border-left: 1px solid var(--sds-color-layout-page-default);
}

.FSTableColumn_borderBottom__rvZ5f {
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.FSTableColumn_borderTop__pTi3A {
  border-top: 1px solid var(--sds-color-layout-page-default);
}

.FSTableColumn_stickyGroupHeader__Klz0W {
  box-shadow: 0 1px 0 var(--sds-color-layout-page-default);
  background-color: var(--sds-color-layout-background-default);
  position: -webkit-sticky;
  position: sticky;
  top: 47px;
}

.FSTableColumn_stuckGroupHeader__uSiku {
  box-shadow: 0 1px 0 var(--sds-color-layout-divider-subtle);
}

.FSTableFooter_footer__O0_ea {
  box-shadow: 0 -1px 0 0 var(--sds-color-layout-page-default); /* Border top */
}

.FSTableHeader_header__eHUaA {
  color: var(--sds-color-text-secondary);
}

.FSTableHeader_sticky__mvhgt {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.FSTable_table__Taxob {
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.FSTable_fullWidth___AaId {
  width: 100%;
}

.FSTable_caption__5h4js {
  color: var(--sds-color-text-secondary);
}

.SkeletonTable_tableGridRow__Au_v1 {
  display: grid;
  align-items: center;
  padding: var(--sds-base-size-m);
  border: 1px solid transparent;
  height: 82px;
}

  .SkeletonTable_tableGridRow__Au_v1:first-child {
    height: 65px;
  }

  .SkeletonTable_tableGridRow__Au_v1:last-child {
    opacity: 0.1;
  }

  .SkeletonTable_tableGridRow__Au_v1:nth-last-child(2) {
    opacity: 0.4;
  }

  .SkeletonTable_tableGridRow__Au_v1:nth-last-child(3) {
    opacity: 0.8;
  }

  .SkeletonTable_tableGridRow__Au_v1 :last-child {
    justify-self: end;
  }

.StickyBottomFooter_buttonsContainer__N_JaT {
  background-color: var(--sds-color-layout-background-default);
  position: -webkit-sticky;
  position: sticky;
  bottom: var(--sds-space-padding-small);
  max-width: 700px;
  min-width: -webkit-max-content;
  min-width: max-content;
  margin: var(--sds-space-padding-medium) auto 0;
  padding: var(--sds-space-padding-small) var(--fsa-space-padding-medium1);
  border-radius: var(--sds-space-border-radius-small);
  border: 1px solid var(--sds-color-brand-neutral-subtle);
  box-shadow: var(--sds-effect-shadow-elevated-default);
  z-index: 10;
}

.Alert_alert__P9cMP {
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 25%);
  border-radius: var(--sds-space-border-radius-small);
  color: var(--sds-color-text-primary);
  padding: var(--sds-space-padding-small) var(--sds-space-padding-medium);
  display: flex;
  align-items: center;
  min-width: 300px;
  gap: var(--sds-space-gap-small);
}

.Alert_alert--paragraph__fYiu0 {
  flex: 1;
  padding-right: 2rem;
}

.Alert_alert--buttons__kzSk3 {
  display: flex;
  flex-direction: row;
  gap: var(--sds-space-gap-medium);
  align-items: center;
}

.Alert_alert--success__Q4zvu {
  background-color: var(--sds-color-support-success-subtle);
}

.Alert_alert--error__dw6no {
  background-color: var(--sds-color-support-critical-subtle);
}

.Alert_alert--warning__1OpSB {
  background-color: var(--sds-color-support-warning-subtle);
}

.Alert_alert--info__3DKi5 {
  background-color: var(--sds-color-support-info-subtle);
}

.Snackbar_snackbarContainer___Vf4h {
  position: fixed;
  top: 10px;
  left: 50%;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  z-index: 9999;
  animation-duration: 200ms;
}

  .Snackbar_snackbarContainer___Vf4h .Snackbar_list-item__aQT40 {
    list-style: none;
  }

.Snackbar_slidein__uf8rg {
  animation-duration: 300ms;
  animation-name: Snackbar_slide-in__sTrYI;
}

.Snackbar_slideout__CXe8b {
  animation-duration: 300ms;
  animation-name: Snackbar_slide-out__d4pgV;
}

@keyframes Snackbar_slide-in__sTrYI {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0); /* End at the original position */
  }
}

@keyframes Snackbar_slide-out__d4pgV {
  from {
    opacity: 1;
    transform: translateX(0); /* Start at original position */
  }

  to {
    opacity: 0;
    transform: translateX(-100%); /* Slide out to right */
  }
}

.MenuItem_menuItem__O0iYl {
  /* Disable ligatures that turn text like "(3)" into a circle with a number inside */
  font-variant-ligatures: none;
}

  .MenuItem_menuItem__O0iYl > * {
    display: contents;
  }

.MenuItem_submenuItem__IzSMD {
  padding-left: var(--sds-space-padding-medium);
}

.InfoNotification_notification__d6S8Y {
  border-radius: var(--sds-space-border-radius-small);
  color: var(--sds-color-text-secondary);
  padding: 0.25rem;
  margin: var(--sds-space-padding-small) var(--sds-space-padding-large);
  background-color: var(--sds-color-support-warning-subtle);
  text-align: center;
}

.PageArrangementFullRow_container__UKSU9 {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem 0;
}

.PageArrangementFullRow_rightAlign__J5CRL {
  justify-content: flex-end;
}

.Divider_divider__zIn0G {
  margin-top: var(--sds-space-padding-small);
  margin-bottom: var(--sds-space-padding-small);
  border-bottom: 2px solid var(--sds-color-layout-divider-subtle);
  width: 100%;
}

.SignOutButton_signOutButton__5WMuD {
  justify-content: center;
}

.UserInfo_username__qra5l,
.UserInfo_userTitle__Jm5c8 {
  text-align: right;
}

.header_headerWrapper__hnBHo {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  --header-height: 5rem;

  z-index: 20;
}

  .header_headerWrapper__hnBHo .header_header__HFXj4 {
    background-color: var(--sds-color-interaction-primary-subtle-default);
    padding-right: var(--sds-space-padding-small);
    z-index: 20;
    height: 5rem;
  }

  .header_headerWrapper__hnBHo .sds-drawer-button__label-primary {
    display: inline;
  }

.header_menu__oay9_ {
  padding: var(--sds-base-size-relative-s1);
}

.header_divider__4wbSi {
  border: var(--sds-space-border-weight-thin) solid var(--sds-color-layout-divider-subtle);
  height: var(--sds-base-size-l);
  border-radius: var(--sds-base-size-s1);
}

.header_logo__RDlt7 {
  text-decoration: none;
  font-size: var(--sds-typography-body-fontsize-lead);
  padding-left: var(--sds-base-size-relative-s1);
}

.header_logo__RDlt7:hover {
    background-color: transparent;
  }

.header_fixedWidthText__6ozPR {
  display: inline-block;
  width: var(--sds-base-size-relative-l);
}

.header_dropdownMenu__Dye_D {
  position: absolute;
  top: 100%;
  left: 0;
  width: -webkit-fit-content;
  width: fit-content;
  background-color: var(--sds-color-layout-background-default);
  border-right: 1px solid var(--sds-color-layout-divider-subtle);
  padding: var(--sds-space-padding-small);
  box-shadow: var(--sds-effect-shadow-elevated-default);
  z-index: 15;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sds-space-padding-medium);
}

.header_backButton__t4eao {
  width: -webkit-fit-content;
  width: fit-content;
}

.header_overlay__5suwk {
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--header-height));
  background-color: var(--sds-color-layout-page-overlay);
  z-index: 10;
}

.ReadOnlyTextField_readOnlyTextFieldInput__4tpHc {
    color: var(--sds-color-text-primary);
    font-size: var(--sds-typography-body-fontsize-regular);
    font-weight: var(--sds-typography-weight-regular);
    line-height: var(--sds-typography-body-lineheight-regular);
    padding: var(--sds-space-padding-small);
  }

.CopyExistingModal_modal__hFU7V {
  overflow: visible;
}

.PoengklasseCombobox_poengtypeRow__DqqGZ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--sds-space-gap-medium);
  align-items: end;
}

.PoengklasseCombobox_line__LxRlj {
  width: 100%;
}

.Surface_surfaceWrapper__xdRuM {
  position: relative;
  display: contents;
}

.Surface_surface__F2uW5 {
  position: relative;
}

.Surface_surface__F2uW5.Surface_fullWidth__u_Rnm {
    width: 100%;
  }

.Surface_surface__F2uW5.Surface_border__0EUNL {
    border: 1px solid;
  }

.Surface_surface__F2uW5.Surface_padding-minimal__2M5K_ {
      padding: var(--sds-space-padding-minimal);
    }

.Surface_surface__F2uW5.Surface_padding-tiny__CuBt5 {
      padding: var(--sds-space-padding-tiny);
    }

.Surface_surface__F2uW5.Surface_padding-small__9aWQO {
      padding: var(--sds-space-padding-small);
    }

.Surface_surface__F2uW5.Surface_padding-medium__W_frO {
      padding: var(--sds-space-padding-medium);
    }

.Surface_surface__F2uW5.Surface_padding-medium1__c08kp {
      /* TODO: Change to --sds-space-padding-medium1 when implemented in designsystem */
      padding: var(--fsa-space-padding-medium1);
    }

.Surface_surface__F2uW5.Surface_padding-large__wrbga {
      padding: var(--sds-space-padding-large);
    }

.Surface_surface__F2uW5.Surface_padding-huge__GuUi2 {
      padding: var(--sds-space-padding-huge);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-minimal__NolQy {
      padding-left: var(--sds-space-padding-minimal);
      padding-right: var(--sds-space-padding-minimal);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-tiny__C4PX5 {
      padding-left: var(--sds-space-padding-tiny);
      padding-right: var(--sds-space-padding-tiny);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-small__os4gZ {
      padding-left: var(--sds-space-padding-small);
      padding-right: var(--sds-space-padding-small);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-medium__nsgw_ {
      padding-left: var(--sds-space-padding-medium);
      padding-right: var(--sds-space-padding-medium);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-medium1__fjYRH {
      padding-left: var(--fsa-space-padding-medium1);
      padding-right: var(--fsa-space-padding-medium1);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-large__GLPrV {
      padding-left: var(--sds-space-padding-large);
      padding-right: var(--sds-space-padding-large);
    }

.Surface_surface__F2uW5.Surface_paddingHorizontal-huge__Sx_wM {
      padding-left: var(--sds-space-padding-huge);
      padding-right: var(--sds-space-padding-huge);
    }

.Surface_surface__F2uW5.Surface_borderRadius-none__U_jLW {
      border-radius: 0;
    }

.Surface_surface__F2uW5.Surface_borderRadius-minimal__Hf8jU {
      border-radius: var(--sds-space-border-radius-minimal);
    }

.Surface_surface__F2uW5.Surface_borderRadius-small__u7I_3 {
      border-radius: var(--sds-space-border-radius-small);
    }

.Surface_surface__F2uW5.Surface_borderRadius-medium__la5US {
      border-radius: var(--sds-space-border-radius-medium);
    }

.Surface_surface__F2uW5.Surface_borderRadius-large__79vRk {
      border-radius: var(--sds-space-border-radius-large);
    }

.Surface_surface__F2uW5.Surface_borderRadius-full__BIzrN {
      border-radius: var(--sds-space-border-radius-full);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-default__wRzqS {
      background: var(--sds-color-layout-background-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-primary__yWseB {
      background: var(--sds-color-layout-background-primary);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-neutral__0l_s3 {
      background: var(--sds-color-layout-background-neutral);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-info__uL_tm {
      background: var(--sds-color-layout-background-info);
      border-color: var(--sds-color-support-info-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-success__H_jnZ {
      background: var(--sds-color-layout-background-success);
      border-color: var(--sds-color-support-success-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-warning__xEGV6 {
      background: var(--sds-color-layout-background-warning);
      border-color: var(--sds-color-support-warning-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-critical__o6JQy {
      background: var(--sds-color-layout-background-critical);
      border-color: var(--sds-color-support-critical-subtle);
    }

.Surface_surface__F2uW5.Surface_backgroundColor-subtle__lyV_O {
      background: var(--sds-color-layout-page-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surface__F2uW5.Surface_shadow-elevated-default__a3Rdj {
      box-shadow: var(--sds-effect-shadow-elevated-default);
    }

.Surface_surface__F2uW5.Surface_shadow-elevated-hover__k_jiz {
      box-shadow: var(--sds-effect-shadow-elevated-hover);
    }

.Surface_surfaceFooter__UhBQX {
  padding: var(--sds-space-padding-medium);
  border-bottom-left-radius: var(--sds-space-border-radius-large);
  border-bottom-right-radius: var(--sds-space-border-radius-large);
  padding-top: calc(var(--sds-space-padding-medium) + 1.5rem);
  padding-bottom: calc(var(--sds-space-padding-medium));
  margin-top: -1.5rem;
}

.Surface_surfaceFooter__UhBQX.Surface_fullWidth__u_Rnm {
    width: 100%;
  }

.Surface_surfaceFooter__UhBQX.Surface_border__0EUNL {
    border: 3px solid;
  }

.Surface_surfaceFooter__UhBQX.Surface_shadow-elevated-default__a3Rdj {
      box-shadow: var(--sds-effect-shadow-elevated-default);
    }

.Surface_surfaceFooter__UhBQX.Surface_shadow-elevated-hover__k_jiz {
      box-shadow: var(--sds-effect-shadow-elevated-hover);
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-none__U_jLW {
      border-radius: 0;
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-minimal__Hf8jU {
      border-bottom-left-radius: var(--sds-space-border-radius-minimal);
      border-bottom-right-radius: var(--sds-space-border-radius-minimal);
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-small__u7I_3 {
      border-bottom-left-radius: var(--sds-space-border-radius-small);
      border-bottom-right-radius: var(--sds-space-border-radius-small);
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-medium__la5US {
      border-bottom-left-radius: var(--sds-space-border-radius-medium);
      border-bottom-right-radius: var(--sds-space-border-radius-medium);
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-large__79vRk {
      border-bottom-left-radius: var(--sds-space-border-radius-large);
      border-bottom-right-radius: var(--sds-space-border-radius-large);
    }

.Surface_surfaceFooter__UhBQX.Surface_borderRadius-full__BIzrN {
      border-bottom-left-radius: var(--sds-space-border-radius-full);
      border-bottom-right-radius: var(--sds-space-border-radius-full);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-minimal__rtu9t {
      padding-top: calc(var(--sds-space-padding-minimal) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-minimal));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-tiny__8CeGd {
      padding-top: calc(var(--sds-space-padding-tiny) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-tiny));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-small__dtZAH {
      padding-top: calc(var(--sds-space-padding-small) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-small));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-medium__6Fld6 {
      padding-top: calc(var(--sds-space-padding-medium) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-medium));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-medium1__kK8R7 {
      padding-top: calc(var(--fsa-space-padding-medium1) + 1.5rem);
      padding-bottom: calc(var(--fsa-space-padding-medium1));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-large__YCzxT {
      padding-top: calc(var(--sds-space-padding-large) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-large));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingVertical-huge__hcs0H {
      padding-top: calc(var(--sds-space-padding-huge) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-huge));
      margin-top: -1.5rem;
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-minimal__NolQy {
      padding-left: var(--sds-space-padding-minimal);
      padding-right: var(--sds-space-padding-minimal);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-tiny__C4PX5 {
      padding-left: var(--sds-space-padding-tiny);
      padding-right: var(--sds-space-padding-tiny);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-small__os4gZ {
      padding-left: var(--sds-space-padding-small);
      padding-right: var(--sds-space-padding-small);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-medium__nsgw_ {
      padding-left: var(--sds-space-padding-medium);
      padding-right: var(--sds-space-padding-medium);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-medium1__fjYRH {
      padding-left: var(--fsa-space-padding-medium1);
      padding-right: var(--fsa-space-padding-medium1);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-large__GLPrV {
      padding-left: var(--sds-space-padding-large);
      padding-right: var(--sds-space-padding-large);
    }

.Surface_surfaceFooter__UhBQX.Surface_paddingHorizontal-huge__Sx_wM {
      padding-left: var(--sds-space-padding-huge);
      padding-right: var(--sds-space-padding-huge);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-default__wRzqS {
      background-color: var(--sds-color-layout-background-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-primary__yWseB {
      background-color: var(--sds-color-layout-background-primary);
      border-color: var(--sds-color-layout-background-default);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-neutral__0l_s3 {
      background-color: var(--sds-color-layout-background-neutral);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-info__uL_tm {
      background-color: var(--sds-color-layout-background-info);
      border-color: var(--sds-color-support-info-subtle);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-success__H_jnZ {
      background-color: var(--sds-color-layout-background-success);
      border-color: var(--sds-color-support-success-subtle);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-warning__xEGV6 {
      background-color: var(--sds-color-layout-background-warning);
      border-color: var(--sds-color-support-warning-subtle);
    }

.Surface_surfaceFooter__UhBQX.Surface_backgroundColor-critical__o6JQy {
      background-color: var(--sds-color-layout-background-critical);
      border-color: var(--sds-color-support-critical-subtle);
    }

.CommandGroupComponent_commandButton__Abggm {
  background: none;
  border-radius: var(--sds-space-border-radius-small);
  border: none;
  cursor: pointer;
  padding: var(--sds-space-padding-tiny);
  background-color: var(--sds-color-interaction-primary-subtle-default);
}

  .CommandGroupComponent_commandButton__Abggm:hover {
    background-color: var(--sds-color-interaction-primary-subtle-highlight);
  }

  .CommandGroupComponent_commandButton__Abggm:focus {
    background-color: var(--sds-color-interaction-primary-subtle-highlight);
    outline: var(--sds-focus-outline);
  }

.CommandGroupComponent_linkButton__cf4EQ {
  padding: var(--sds-space-padding-minimal);
  border-radius: var(--sds-space-border-radius-small);
  background-color: var(--sds-color-interaction-primary-subtle-highlight);
}

.CommandGroupComponent_commandButton__Abggm:hover .CommandGroupComponent_linkButton__cf4EQ,
  .CommandGroupComponent_commandButton__Abggm:focus .CommandGroupComponent_linkButton__cf4EQ {
    background-color: var(--sds-color-interaction-primary-subtle-default);
  }

.CommandGroupComponent_badge__TnkWX {
  background-color: var(--sds-color-interaction-primary-subtle-highlight);
}

.CommandGroupComponent_commandButton__Abggm:hover .CommandGroupComponent_badge__TnkWX,
  .CommandGroupComponent_commandButton__Abggm:focus .CommandGroupComponent_badge__TnkWX {
    background-color: var(--sds-color-interaction-primary-subtle-default);
  }

.CommandPalette_results__t7pHa {
  overflow-y: auto;
  height: 500px;
}

.CommandPalette_dialog__VHToa {
  border: 0;
  padding: 0;
  background: transparent;
}

.CommandPalette_input__xvfAJ {
  width: 500px;
}

.TutorialSurface_surfaceSize__dok89 {
  width: 20rem;
}

.TutorialSurface_surfaceContentWrapper__nPjPH {
  min-height: 20rem;
  text-align: center;
}

.page_container__ThF9a {
  height: 100%;
}

.ReorderItem_iconButton__GyVJP {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--sds-color-text-primary);
  transition: color 0.2s;
  font-size: 2rem;
}

  .ReorderItem_iconButton__GyVJP:disabled {
    color: var(--sds-color-interaction-neutral-subtle-default);
    cursor: not-allowed;
    background: none;
  }

.ReorderItem_centered__fNaSK {
  justify-self: center;
  display: flex;
}

.ReorderItem_rowGrabbed__UQrpC {
  box-shadow: var(--sds-effect-shadow-elevated-default);
  background-color: var(--sds-color-layout-background-default);
}

.ReorderItem_tableRow__H9l5K {
  padding: 0;
  border-bottom: 1px solid var(--sds-color-layout-divider-subtle);
  transition: box-shadow 0.2s ease-in-out;
}

.ReorderItem_tableRow__H9l5K:last-child {
    border-bottom: none;
  }

.ReorderItem_movePriorityIcon__nHR_b {
  width: 2rem;
  height: 2rem;
  place-self: center center;
  touch-action: none;
  cursor: grab;
}

.ReorderItem_movePriorityIcon__nHR_b :hover {
    cursor: grab;
  }

.ReorderItem_movePriorityIcon__nHR_b :active {
    cursor: grabbing;
  }

@media (width < 720px) {
  .ReorderItem_movePriorityIcon__nHR_b {
    order: 1;
  }
}

.ReorderItem_priorityWrapper__P7OZV {
  width: -webkit-max-content;
  width: max-content;
}

.ReorderItem_tableCell__vXOqF {
  padding: var(--table-padding-cell);
}

@media (width > 720px) {
  .ReorderItem_tableCell__vXOqF {
    padding: var(--sds-space-padding-minimal) var(--table-padding-cell);
  }

    .ReorderItem_tableCell__vXOqF:first-child {
      padding-left: var(--sds-space-padding-medium);
    }
}

.TablePrioritizable_table__PdsI_ {
  overflow: hidden;
}

.TablePrioritizable_centered__uJaH_ {
  text-align: center;
}

.TablePrioritizable_tableCell__h84xK {
  padding: var(--table-padding-cell);
}

@media (width > 720px) {
  .TablePrioritizable_tableCell__h84xK {
    padding: var(--sds-space-padding-minimal) var(--table-padding-cell);
  }
}

.UtdanningstilbudEndrePage_divider___TC5v {
  color: var(--sds-color-layout-divider-subtle);
  width: 100%;
  border: none;
  border-bottom: 2px solid;
  margin: 1rem 0;
}

.UtdanningstilbudEndrePage_select__76j5a {
  min-width: 15rem;
  max-width: 25rem;
  width: auto;
}

.UtdanningstilbudEndrePage_combobox__QbiZw {
  min-width: 15rem;
  max-width: 25rem;
}

.UtdanningstilbudEndrePage_centered__FL1A7 {
  justify-self: center;
  display: flex;
}

.UtdanningstilbudEndrePage_participantsInput__hxNf_ {
  width: 4rem;
}

.ApplicationRow_title__lIFDY {
  flex: 1;
}

.ApplicationRow_alternatives__J8vI9 {
  flex-basis: 45%;
  align-self: center;
}

.ApplicationRow_status__eHLP4 {
  flex-basis: 10%;
  align-self: center;
  text-align: right;
}

.StudierettNav_studieretterNav__MOoPT {
  text-align: center;
  white-space: nowrap;
}

.StudierettNav_iconButton__C__6b {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.StudierettNav_iconButton__C__6b:disabled {
    color: grey;
    cursor: inherit;
  }

.Studierett_gridContainer__hm_1o {
  display: grid;
  grid-template-columns: 45% 25% 30%;
  width: 100%;
}

.PersonDetails_container__gd7BD {
  max-width: var(--sds-base-breakpoint-ultrawide);
}

.PersonDetails_portrait__ou1Gz {
  background-color: var(--sds-color-layout-background-neutral);
  padding: var(--sds-space-padding-medium) 0;
  border-radius: var(--sds-space-border-radius-small);
}

.PersonDetails_sendButton__ZeS2w {
  width: 100%;
}

.GrunnlagDetails_input__tt7MU {
  max-width: calc(var(--sds-base-size-xxl) * 1.5);
}

.BasicInfoCard_gridTwoColumns__u4d5m {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--sds-space-gap-large);
}

.KravelementDetails_input__F8W9F {
  max-width: calc(var(--sds-base-size-xxl) * 1.5);
}

.KvotesporsmalDetails_input__sEajD {
  max-width: calc(var(--sds-base-size-xxl) * 1.5);
}

.PoengformelDetails_input__etu3_ {
  max-width: calc(var(--sds-base-size-xxl) * 1.5);
}

.OpprettSoknadView_flexContainer__ipu1l {
  margin-top: var(--sds-space-gap-medium);
}

.OpprettSoknadView_priorityContainer__FgFe1 {
  flex-grow: 2;
  min-width: 400px;
}

.OpprettSoknadView_filesContainer__QofdT {
  flex-grow: 1;
  min-width: 400px;
}

.LinkActionButton_linkStyle__Wetja {
  cursor: pointer;
}

.ItemWithFooter_wrapper__klLJZ {
  border: 1px solid var(--sds-color-layout-divider-subtle);
}

.ItemWithFooter_details__AmaQY {
  background-color: var(--sds-color-layout-page-default);
  padding: var(--sds-space-padding-minimal) var(--sds-space-padding-small);
}

.DocumentItem_wrapper__9_EjN {
  padding: var(--sds-space-padding-minimal) var(--sds-space-padding-small)
    var(--sds-space-padding-small);
}

.DataTable_hiddenLabel__tlMjM .sds-checkbox__input-label {
    padding: 0;
  }

.DataTable_tableRow__bRC6q:hover {
    background-color: var(--sds-color-interaction-primary-transparent-highlight);
    cursor: pointer;
  }

.DataTable_alignLastCellRight__vza7t:last-child {
    display: flex;
    justify-content: flex-end;
  }

.PlasstildelingsTable_header___lyxa {
  display: flex;
  align-items: center;
  gap: var(--sds-space-padding-medium);
  margin-bottom: var(--sds-space-padding-small);
}

.PlasstildelingsTable_container__eRyiR {
  background-color: var(--sds-color-layout-background-default);
  border-radius: var(--sds-space-border-radius-medium);
  padding: var(--sds-space-padding-medium);
}

.SearchUtdanningsinstansTable_saveButton__pykbT {
  align-self: flex-end;
  font-variant-ligatures: none;
}

.UtdanningstilbudList_badge__ZmMNB {
  white-space: nowrap;
}

.UtdanningstilbudPage_buttons__qyDer {
  padding-top: var(--sds-space-padding-large);
}

  .UtdanningstilbudPage_buttons__qyDer .UtdanningstilbudPage_warning__wuZbq > * {
    color: var(--sds-color-interaction-danger-strong-default);
  }

.BadgeWithAction_badge__oZVkC {
  padding: var(--sds-space-padding-small);
  font-size: var(--sds-typography-body-fontsize-regular);
  gap: 0;
}

.BadgeWithAction_content__zu4B5 {
  padding: 0 var(--sds-space-padding-tiny);
}

.BadgeWithAction_button__gOa7e {
  padding: var(--sds-space-padding-tiny);
  margin: calc(-1 * var(--sds-space-padding-tiny));
  color: currentcolor;
}

.BadgeWithAction_button__gOa7e:hover {
    background-color: var(--sds-color-interaction-primary-subtle-pressed);
  }

.GroupSearch_filters__cZW2a {
  padding-right: 2rem;
}

.GrunnlagTable_search__N4_qk {
  max-width: 400px;
}

.kompetanseregelverk_buttons__zQXvk {
  margin-top: var(--sds-space-gap-large);
}

.kompetanseregelverk_infoButton__eNcbZ {
  background-color: var(--sds-color-support-info-strong);
}

.KravelementerTable_search__jfONM {
  max-width: 400px;
}

.Kvoteregelverk_pageWrapper__q_u3q {
  display: flex;
  flex-direction: column;
  gap: var(--sds-space-gap-medium);
}

.Kvoteregelverk_buttons__tbb0S {
  margin-top: var(--sds-space-gap-large);
}

.KvotesporsmalTable_search__rywXq {
  max-width: 400px;
}

.DashboardView_surface__OYyCM {
  background: var(--sds-color-layout-background-primary);
  padding: var(--sds-space-padding-large) !important;
  padding-top: var(--sds-space-padding-minimal) !important;
}

.PoengformlerTable_search__MKSAn {
  max-width: 400px;
}

.GrunnlagRow_grunnlagRow__5I37i {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  gap: var(--sds-space-gap-medium);
  margin: var(--sds-space-padding-medium) 0;
}

.GrunnlagRow_wrapperPoengTyper__LI1rh {
  width: '100%';
}

.GrunnlagRow_threeColumnGrid__RwDSK {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.RangeringBasicInfoCard_gridTwoColumns__i_ZVX {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--sds-space-gap-large);
}

.OpptakOverviewView_tableContainer__W8M_T {
  background-color: var(--sds-color-layout-background-default);
  border-radius: var(--sds-space-border-radius-medium);
  padding: var(--sds-space-padding-medium);
}

.OpptakOverviewView_title___3vKB {
  padding-bottom: var(--sds-space-padding-large);
}

.SakOverviewTable_title___NOy9 {
  padding-bottom: var(--sds-space-padding-large);
}

.SakOverviewTable_tableContainer__cxFdW {
  background-color: var(--sds-color-layout-background-default);
  border-radius: var(--sds-space-border-radius-medium);
  padding: var(--sds-space-padding-medium);
}

.SakOverviewTable_cellMaxWidth__MKvRG {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.SakOverviewTable_topBarContainer__Zx8AE {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.SakOverviewTable_searchContainer__3LGJF {
  max-width: 400px;
  margin: 0 0 var(--sds-space-padding-medium) var(--sds-space-padding-medium);
  flex-grow: 1;
}

.SakOverviewTable_menuContainer__QP99m {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.SakOverviewTable_filterContainer__qIF9B {
  margin: 0 0 var(--sds-space-padding-medium) var(--sds-space-padding-medium);
}

.SakOverviewTable_filterToggle__LbWy3:not(:last-child) {
  margin: 0 var(--sds-space-padding-tiny) var(--sds-space-padding-tiny) 0;
}

.SakOverviewTable_toggleDisabled__qqnX5 {
  pointer-events: none;
  opacity: 0.5;
}

.SakOverviewView_title__IWAa3 {
  padding-left: var(--sds-space-padding-small);
  margin-bottom: var(--sds-space-padding-small);
}

.PoengberegningDynamicCardRow_pointsCell__jkOJL {
  width: 50%;
}

.PoengberegningDynamicCardRow_row__0RSh6 {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.PoengberegningDynamicCardRow_tableCellInputFields__M_PyB {
  height: 160px;
  align-content: normal;
}

.PoengberegningDynamicCardRow_hasErrorText__awJ74 {
  align-content: end;
}

.SakBehandlingsView_dashboardItem__RVo7z {
  display: flex;
  flex-direction: column;
  gap: var(--sds-space-padding-medium);
}

.SakBehandlingsView_containerGrid__BvW6p {
  display: grid;
  grid-template-columns: minmax(400px, 480px) minmax(700px, 2fr) minmax(600px, 1fr);
  gap: var(--sds-space-padding-small) var(--sds-space-padding-medium);
  grid-auto-columns: auto;
}

@media (width <= 1800px) {
  .SakBehandlingsView_containerGrid__BvW6p {
    grid-template-columns: minmax(400px, 480px) minmax(600px, 3fr);
  }

  .SakBehandlingsView_rightContainer__7aAMM {
    grid-column: 2;
    grid-row: 2;
  }
}

@media (width <= 1070px) {
  .SakBehandlingsView_containerGrid__BvW6p {
    grid-template-columns: 1fr;
  }

  .SakBehandlingsView_rightContainer__7aAMM {
    grid-column: 1;
    grid-row: 3;
  }
}

.SakBehandlingsView_cannotEditMessage__VgsQa {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background-color: var(--sds-color-support-warning-subtle);
  padding: 1rem;
  margin: 0 0 1rem;
}

