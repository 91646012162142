.buttonsContainer {
  background-color: var(--sds-color-layout-background-default);
  position: sticky;
  bottom: var(--sds-space-padding-small);
  max-width: 700px;
  min-width: max-content;
  margin: var(--sds-space-padding-medium) auto 0;
  padding: var(--sds-space-padding-small) var(--fsa-space-padding-medium1);
  border-radius: var(--sds-space-border-radius-small);
  border: 1px solid var(--sds-color-brand-neutral-subtle);
  box-shadow: var(--sds-effect-shadow-elevated-default);
  z-index: 10;
}
