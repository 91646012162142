.divider {
  color: var(--sds-color-layout-divider-subtle);
  width: 100%;
  border: none;
  border-bottom: 2px solid;
  margin: 1rem 0;
}

.select {
  min-width: 15rem;
  max-width: 25rem;
  width: auto;
}

.combobox {
  min-width: 15rem;
  max-width: 25rem;
}

.centered {
  justify-self: center;
  display: flex;
}

.participantsInput {
  width: 4rem;
}
