.alert {
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 25%);
  border-radius: var(--sds-space-border-radius-small);
  color: var(--sds-color-text-primary);
  padding: var(--sds-space-padding-small) var(--sds-space-padding-medium);
  display: flex;
  align-items: center;
  min-width: 300px;
  gap: var(--sds-space-gap-small);
}

.alert--paragraph {
  flex: 1;
  padding-right: 2rem;
}

.alert--buttons {
  display: flex;
  flex-direction: row;
  gap: var(--sds-space-gap-medium);
  align-items: center;
}

.alert--success {
  background-color: var(--sds-color-support-success-subtle);
}

.alert--error {
  background-color: var(--sds-color-support-critical-subtle);
}

.alert--warning {
  background-color: var(--sds-color-support-warning-subtle);
}

.alert--info {
  background-color: var(--sds-color-support-info-subtle);
}
