.title {
  flex: 1;
}

.alternatives {
  flex-basis: 45%;
  align-self: center;
}

.status {
  flex-basis: 10%;
  align-self: center;
  text-align: right;
}
