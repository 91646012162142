.container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem 0;
}

.rightAlign {
  justify-content: flex-end;
}
