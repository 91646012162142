.grunnlagRow {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  gap: var(--sds-space-gap-medium);
  margin: var(--sds-space-padding-medium) 0;
}

.wrapperPoengTyper {
  width: '100%';
}

.threeColumnGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
