.commandButton {
  background: none;
  border-radius: var(--sds-space-border-radius-small);
  border: none;
  cursor: pointer;
  padding: var(--sds-space-padding-tiny);
  background-color: var(--sds-color-interaction-primary-subtle-default);

  &:hover {
    background-color: var(--sds-color-interaction-primary-subtle-highlight);
  }

  &:focus {
    background-color: var(--sds-color-interaction-primary-subtle-highlight);
    outline: var(--sds-focus-outline);
  }
}

.linkButton {
  padding: var(--sds-space-padding-minimal);
  border-radius: var(--sds-space-border-radius-small);
  background-color: var(--sds-color-interaction-primary-subtle-highlight);

  .commandButton:hover &,
  .commandButton:focus & {
    background-color: var(--sds-color-interaction-primary-subtle-default);
  }
}

.badge {
  background-color: var(--sds-color-interaction-primary-subtle-highlight);

  .commandButton:hover &,
  .commandButton:focus & {
    background-color: var(--sds-color-interaction-primary-subtle-default);
  }
}
