.flexContainer {
  margin-top: var(--sds-space-gap-medium);
}

.priorityContainer {
  flex-grow: 2;
  min-width: 400px;
}

.filesContainer {
  flex-grow: 1;
  min-width: 400px;
}
