.table {
  overflow: hidden;
}

.centered {
  text-align: center;
}

.tableCell {
  padding: var(--table-padding-cell);
}

@media (width > 720px) {
  .tableCell {
    padding: var(--sds-space-padding-minimal) var(--table-padding-cell);
  }
}
