@import url('../../../node_modules/@sikt/sds-tokens/dist/css/custom-media.css');

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: var(--sds-space-padding-medium) var(--sds-space-padding-medium);

  :global(.sds-drawer) {
    height: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  width: 100%;
  flex: 1;
}

.children {
  min-height: 10rem;
}

.breadcrumbs {
  margin: var(--sds-space-padding-small) 0 var(--sds-space-padding-medium) 0;
}
