.skeleton {
  @keyframes pulse {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }

  background: linear-gradient(90deg, #909090 0%, #dcdcdc 25%, #eaeaea 65%, #909090 100%);
  opacity: 0.4;
  background-size: 200% 100%;
  animation: pulse 1.5s infinite;

  &.radius {
    &-minimal {
      border-radius: var(--sds-space-border-radius-minimal);
    }

    &-small {
      border-radius: var(--sds-space-border-radius-small);
    }

    &-medium {
      border-radius: var(--sds-space-border-radius-medium);
    }

    &-full {
      border-radius: var(--sds-space-border-radius-full);
    }
  }
}
