.header {
  display: flex;
  align-items: center;
  gap: var(--sds-space-padding-medium);
  margin-bottom: var(--sds-space-padding-small);
}

.container {
  background-color: var(--sds-color-layout-background-default);
  border-radius: var(--sds-space-border-radius-medium);
  padding: var(--sds-space-padding-medium);
}
