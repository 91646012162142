.studieretterNav {
  text-align: center;
  white-space: nowrap;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:disabled {
    color: grey;
    cursor: inherit;
  }
}
