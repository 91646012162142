.pointsCell {
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.tableCellInputFields {
  height: 160px;
  align-content: normal;
}

.hasErrorText {
  align-content: end;
}
