.snackbarContainer {
  position: fixed;
  top: 10px;
  left: 50%;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  z-index: 9999;
  animation-duration: 200ms;

  .list-item {
    list-style: none;
  }
}

.slidein {
  animation-duration: 300ms;
  animation-name: slide-in;
}

.slideout {
  animation-duration: 300ms;
  animation-name: slide-out;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0); /* End at the original position */
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0); /* Start at original position */
  }

  to {
    opacity: 0;
    transform: translateX(-100%); /* Slide out to right */
  }
}
