.results {
  overflow-y: auto;
  height: 500px;
}

.dialog {
  border: 0;
  padding: 0;
  background: transparent;
}

.input {
  width: 500px;
}
