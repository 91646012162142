.hiddenLabel {
  :global(.sds-checkbox__input-label) {
    padding: 0;
  }
}

.tableRow {
  &:hover {
    background-color: var(--sds-color-interaction-primary-transparent-highlight);
    cursor: pointer;
  }
}

.alignLastCellRight {
  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
}
