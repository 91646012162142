.badge {
  padding: var(--sds-space-padding-small);
  font-size: var(--sds-typography-body-fontsize-regular);
  gap: 0;
}

.content {
  padding: 0 var(--sds-space-padding-tiny);
}

.button {
  padding: var(--sds-space-padding-tiny);
  margin: calc(-1 * var(--sds-space-padding-tiny));
  color: currentcolor;

  &:hover {
    background-color: var(--sds-color-interaction-primary-subtle-pressed);
  }
}
