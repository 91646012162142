.iconButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--sds-color-text-primary);
  transition: color 0.2s;
  font-size: 2rem;

  &:disabled {
    color: var(--sds-color-interaction-neutral-subtle-default);
    cursor: not-allowed;
    background: none;
  }
}

.centered {
  justify-self: center;
  display: flex;
}

.rowGrabbed {
  box-shadow: var(--sds-effect-shadow-elevated-default);
  background-color: var(--sds-color-layout-background-default);
}

.tableRow {
  padding: 0;
  border-bottom: 1px solid var(--sds-color-layout-divider-subtle);
  transition: box-shadow 0.2s ease-in-out;

  &:last-child {
    border-bottom: none;
  }
}

.movePriorityIcon {
  width: 2rem;
  height: 2rem;
  place-self: center center;
  touch-action: none;
  cursor: grab;

  & :hover {
    cursor: grab;
  }

  & :active {
    cursor: grabbing;
  }
}

@media (width < 720px) {
  .movePriorityIcon {
    order: 1;
  }
}

.priorityWrapper {
  width: max-content;
}

.tableCell {
  padding: var(--table-padding-cell);
}

@media (width > 720px) {
  .tableCell {
    padding: var(--sds-space-padding-minimal) var(--table-padding-cell);

    &:first-child {
      padding-left: var(--sds-space-padding-medium);
    }
  }
}
