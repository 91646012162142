.menuItem {
  /* Disable ligatures that turn text like "(3)" into a circle with a number inside */
  font-variant-ligatures: none;

  & > * {
    display: contents;
  }
}

.submenuItem {
  padding-left: var(--sds-space-padding-medium);
}
