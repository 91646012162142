.tableGridRow {
  display: grid;
  align-items: center;
  padding: var(--sds-base-size-m);
  border: 1px solid transparent;
  height: 82px;

  &:first-child {
    height: 65px;
  }

  &:last-child {
    opacity: 0.1;
  }

  &:nth-last-child(2) {
    opacity: 0.4;
  }

  &:nth-last-child(3) {
    opacity: 0.8;
  }

  :last-child {
    justify-self: end;
  }
}
