.cell {
  color: var(--sds-color-text-secondary);
}

.left {
  display: flex;
  justify-content: flex-start;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.center {
  display: flex;
  justify-content: center;
}
