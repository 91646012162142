.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1.4rem;
}

.left {
  width: 100%;
}

.right {
  width: 100%;
}

@media (width <= 1440px) {
  .container {
    flex-direction: column;

    .left {
      min-width: 100%;
    }
  }
}
