.poengtypeRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--sds-space-gap-medium);
  align-items: end;
}

.line {
  width: 100%;
}
