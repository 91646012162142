.padding {
  padding: var(--sds-space-padding-small);
}

.primaryColor {
  background-color: var(--sds-color-layout-background-primary);
}

.infoColor {
  background-color: var(--sds-color-layout-background-info);
}

.whiteColor {
  background-color: var(--sds-color-layout-background-default);
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.roundedTopLeft {
  border-radius: var(--sds-space-border-radius-medium) 0 0 0;
}

.roundedTopRight {
  border-radius: 0 var(--sds-space-border-radius-medium) 0 0;
}

.borderRight {
  border-right: 1px solid var(--sds-color-layout-page-default);
}

.borderLeft {
  border-left: 1px solid var(--sds-color-layout-page-default);
}

.borderBottom {
  border-bottom: 1px solid var(--sds-color-layout-page-default);
}

.borderTop {
  border-top: 1px solid var(--sds-color-layout-page-default);
}

.stickyGroupHeader {
  box-shadow: 0 1px 0 var(--sds-color-layout-page-default);
  background-color: var(--sds-color-layout-background-default);
  position: sticky;
  top: 47px;
}

.stuckGroupHeader {
  box-shadow: 0 1px 0 var(--sds-color-layout-divider-subtle);
}
