.container {
  max-width: var(--sds-base-breakpoint-ultrawide);
}

.portrait {
  background-color: var(--sds-color-layout-background-neutral);
  padding: var(--sds-space-padding-medium) 0;
  border-radius: var(--sds-space-border-radius-small);
}

.sendButton {
  width: 100%;
}
