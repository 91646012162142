.flex {
  display: flex;

  &.fullWidth {
    width: 100%;
  }

  &.align {
    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-end {
      align-items: end;
    }

    &-flex-end {
      align-items: flex-end;
    }

    &-flex-start {
      align-items: flex-start;
    }

    &-self-end {
      align-items: self-end;
    }

    &-self-start {
      align-items: self-start;
    }

    &-start {
      align-items: start;
    }
  }

  &.justify {
    &-space-around {
      justify-content: space-around;
    }

    &-space-between {
      justify-content: space-between;
    }

    &-space-evenly {
      justify-content: space-evenly;
    }

    &-stretch {
      justify-content: stretch;
    }

    &-flex-end {
      justify-content: flex-end;
    }
  }

  &.gap {
    &-minimal {
      gap: var(--sds-space-gap-minimal);
    }

    &-tiny {
      gap: var(--sds-space-gap-tiny);
    }

    &-small {
      gap: var(--sds-space-gap-small);
    }

    &-medium {
      gap: var(--sds-space-gap-medium);
    }

    &-large {
      gap: var(--sds-space-gap-large);
    }
  }

  &.gapColumn {
    &-minimal {
      column-gap: var(--sds-space-gap-minimal);
    }

    &-tiny {
      column-gap: var(--sds-space-gap-tiny);
    }

    &-small {
      column-gap: var(--sds-space-gap-small);
    }

    &-medium {
      column-gap: var(--sds-space-gap-medium);
    }

    &-large {
      column-gap: var(--sds-space-gap-large);
    }
  }

  &.gapRow {
    &-minimal {
      row-gap: var(--sds-space-gap-minimal);
    }

    &-tiny {
      row-gap: var(--sds-space-gap-tiny);
    }

    &-small {
      row-gap: var(--sds-space-gap-small);
    }

    &-medium {
      row-gap: var(--sds-space-gap-medium);
    }

    &-large {
      row-gap: var(--sds-space-gap-large);
    }
  }

  &.wrap {
    &-wrap {
      flex-wrap: wrap;
    }

    &-nowrap {
      flex-wrap: nowrap;
    }

    &-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  &.flex {
    &-column {
      flex-direction: column;
    }

    &-column-reverse {
      flex-direction: column-reverse;
    }

    &-row {
      flex-direction: row;
    }

    &-row-reverse {
      flex-direction: row-reverse;
    }
  }

  &.grow-0 {
    flex-grow: 0;
  }

  &.grow-1 {
    flex-grow: 1;
  }

  &.grow-2 {
    flex-grow: 2;
  }
}
