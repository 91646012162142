.headerWrapper {
  position: sticky;
  top: 0;

  --header-height: 5rem;

  z-index: 20;

  .header {
    background-color: var(--sds-color-interaction-primary-subtle-default);
    padding-right: var(--sds-space-padding-small);
    z-index: 20;
    height: 5rem;
  }

  :global(.sds-drawer-button__label-primary) {
    display: inline;
  }
}

.menu {
  padding: var(--sds-base-size-relative-s1);
}

.divider {
  border: var(--sds-space-border-weight-thin) solid var(--sds-color-layout-divider-subtle);
  height: var(--sds-base-size-l);
  border-radius: var(--sds-base-size-s1);
}

.logo {
  text-decoration: none;
  font-size: var(--sds-typography-body-fontsize-lead);
  padding-left: var(--sds-base-size-relative-s1);

  &:hover {
    background-color: transparent;
  }
}

.fixedWidthText {
  display: inline-block;
  width: var(--sds-base-size-relative-l);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  background-color: var(--sds-color-layout-background-default);
  border-right: 1px solid var(--sds-color-layout-divider-subtle);
  padding: var(--sds-space-padding-small);
  box-shadow: var(--sds-effect-shadow-elevated-default);
  z-index: 15;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sds-space-padding-medium);
}

.backButton {
  width: fit-content;
}

.overlay {
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--header-height));
  background-color: var(--sds-color-layout-page-overlay);
  z-index: 10;
}
