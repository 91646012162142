.surfaceWrapper {
  position: relative;
  display: contents;
}

.surface {
  position: relative;

  &.fullWidth {
    width: 100%;
  }

  &.border {
    border: 1px solid;
  }

  &.padding {
    &-minimal {
      padding: var(--sds-space-padding-minimal);
    }

    &-tiny {
      padding: var(--sds-space-padding-tiny);
    }

    &-small {
      padding: var(--sds-space-padding-small);
    }

    &-medium {
      padding: var(--sds-space-padding-medium);
    }

    &-medium1 {
      /* TODO: Change to --sds-space-padding-medium1 when implemented in designsystem */
      padding: var(--fsa-space-padding-medium1);
    }

    &-large {
      padding: var(--sds-space-padding-large);
    }

    &-huge {
      padding: var(--sds-space-padding-huge);
    }
  }

  &.paddingHorizontal {
    &-minimal {
      padding-left: var(--sds-space-padding-minimal);
      padding-right: var(--sds-space-padding-minimal);
    }

    &-tiny {
      padding-left: var(--sds-space-padding-tiny);
      padding-right: var(--sds-space-padding-tiny);
    }

    &-small {
      padding-left: var(--sds-space-padding-small);
      padding-right: var(--sds-space-padding-small);
    }

    &-medium {
      padding-left: var(--sds-space-padding-medium);
      padding-right: var(--sds-space-padding-medium);
    }

    &-medium1 {
      padding-left: var(--fsa-space-padding-medium1);
      padding-right: var(--fsa-space-padding-medium1);
    }

    &-large {
      padding-left: var(--sds-space-padding-large);
      padding-right: var(--sds-space-padding-large);
    }

    &-huge {
      padding-left: var(--sds-space-padding-huge);
      padding-right: var(--sds-space-padding-huge);
    }
  }

  &.borderRadius {
    &-none {
      border-radius: 0;
    }

    &-minimal {
      border-radius: var(--sds-space-border-radius-minimal);
    }

    &-small {
      border-radius: var(--sds-space-border-radius-small);
    }

    &-medium {
      border-radius: var(--sds-space-border-radius-medium);
    }

    &-large {
      border-radius: var(--sds-space-border-radius-large);
    }

    &-full {
      border-radius: var(--sds-space-border-radius-full);
    }
  }

  &.backgroundColor {
    &-default {
      background: var(--sds-color-layout-background-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

    &-primary {
      background: var(--sds-color-layout-background-primary);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

    &-neutral {
      background: var(--sds-color-layout-background-neutral);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

    &-info {
      background: var(--sds-color-layout-background-info);
      border-color: var(--sds-color-support-info-subtle);
    }

    &-success {
      background: var(--sds-color-layout-background-success);
      border-color: var(--sds-color-support-success-subtle);
    }

    &-warning {
      background: var(--sds-color-layout-background-warning);
      border-color: var(--sds-color-support-warning-subtle);
    }

    &-critical {
      background: var(--sds-color-layout-background-critical);
      border-color: var(--sds-color-support-critical-subtle);
    }

    &-subtle {
      background: var(--sds-color-layout-page-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }
  }

  &.shadow {
    &-elevated-default {
      box-shadow: var(--sds-effect-shadow-elevated-default);
    }

    &-elevated-hover {
      box-shadow: var(--sds-effect-shadow-elevated-hover);
    }
  }
}

.surfaceFooter {
  padding: var(--sds-space-padding-medium);
  border-bottom-left-radius: var(--sds-space-border-radius-large);
  border-bottom-right-radius: var(--sds-space-border-radius-large);
  padding-top: calc(var(--sds-space-padding-medium) + 1.5rem);
  padding-bottom: calc(var(--sds-space-padding-medium));
  margin-top: -1.5rem;

  &.fullWidth {
    width: 100%;
  }

  &.border {
    border: 3px solid;
  }

  &.shadow {
    &-elevated-default {
      box-shadow: var(--sds-effect-shadow-elevated-default);
    }

    &-elevated-hover {
      box-shadow: var(--sds-effect-shadow-elevated-hover);
    }
  }

  &.borderRadius {
    &-none {
      border-radius: 0;
    }

    &-minimal {
      border-bottom-left-radius: var(--sds-space-border-radius-minimal);
      border-bottom-right-radius: var(--sds-space-border-radius-minimal);
    }

    &-small {
      border-bottom-left-radius: var(--sds-space-border-radius-small);
      border-bottom-right-radius: var(--sds-space-border-radius-small);
    }

    &-medium {
      border-bottom-left-radius: var(--sds-space-border-radius-medium);
      border-bottom-right-radius: var(--sds-space-border-radius-medium);
    }

    &-large {
      border-bottom-left-radius: var(--sds-space-border-radius-large);
      border-bottom-right-radius: var(--sds-space-border-radius-large);
    }

    &-full {
      border-bottom-left-radius: var(--sds-space-border-radius-full);
      border-bottom-right-radius: var(--sds-space-border-radius-full);
    }
  }

  &.paddingVertical {
    &-minimal {
      padding-top: calc(var(--sds-space-padding-minimal) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-minimal));
      margin-top: -1.5rem;
    }

    &-tiny {
      padding-top: calc(var(--sds-space-padding-tiny) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-tiny));
      margin-top: -1.5rem;
    }

    &-small {
      padding-top: calc(var(--sds-space-padding-small) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-small));
      margin-top: -1.5rem;
    }

    &-medium {
      padding-top: calc(var(--sds-space-padding-medium) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-medium));
      margin-top: -1.5rem;
    }

    &-medium1 {
      padding-top: calc(var(--fsa-space-padding-medium1) + 1.5rem);
      padding-bottom: calc(var(--fsa-space-padding-medium1));
      margin-top: -1.5rem;
    }

    &-large {
      padding-top: calc(var(--sds-space-padding-large) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-large));
      margin-top: -1.5rem;
    }

    &-huge {
      padding-top: calc(var(--sds-space-padding-huge) + 1.5rem);
      padding-bottom: calc(var(--sds-space-padding-huge));
      margin-top: -1.5rem;
    }
  }

  &.paddingHorizontal {
    &-minimal {
      padding-left: var(--sds-space-padding-minimal);
      padding-right: var(--sds-space-padding-minimal);
    }

    &-tiny {
      padding-left: var(--sds-space-padding-tiny);
      padding-right: var(--sds-space-padding-tiny);
    }

    &-small {
      padding-left: var(--sds-space-padding-small);
      padding-right: var(--sds-space-padding-small);
    }

    &-medium {
      padding-left: var(--sds-space-padding-medium);
      padding-right: var(--sds-space-padding-medium);
    }

    &-medium1 {
      padding-left: var(--fsa-space-padding-medium1);
      padding-right: var(--fsa-space-padding-medium1);
    }

    &-large {
      padding-left: var(--sds-space-padding-large);
      padding-right: var(--sds-space-padding-large);
    }

    &-huge {
      padding-left: var(--sds-space-padding-huge);
      padding-right: var(--sds-space-padding-huge);
    }
  }

  &.backgroundColor {
    &-default {
      background-color: var(--sds-color-layout-background-default);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

    &-primary {
      background-color: var(--sds-color-layout-background-primary);
      border-color: var(--sds-color-layout-background-default);
    }

    &-neutral {
      background-color: var(--sds-color-layout-background-neutral);
      border-color: var(--sds-color-brand-neutral-subtle);
    }

    &-info {
      background-color: var(--sds-color-layout-background-info);
      border-color: var(--sds-color-support-info-subtle);
    }

    &-success {
      background-color: var(--sds-color-layout-background-success);
      border-color: var(--sds-color-support-success-subtle);
    }

    &-warning {
      background-color: var(--sds-color-layout-background-warning);
      border-color: var(--sds-color-support-warning-subtle);
    }

    &-critical {
      background-color: var(--sds-color-layout-background-critical);
      border-color: var(--sds-color-support-critical-subtle);
    }
  }
}
