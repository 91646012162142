.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: var(--sds-space-padding-medium) var(--sds-space-padding-medium);
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 130rem;
  width: 100%;
}

.children {
  min-height: 10rem;
}

.backButton {
  margin: var(--sds-space-padding-small) 0 var(--sds-space-padding-medium) 0;
}
