.dashboardItem {
  display: flex;
  flex-direction: column;
  gap: var(--sds-space-padding-medium);
}

.containerGrid {
  display: grid;
  grid-template-columns: minmax(400px, 480px) minmax(700px, 2fr) minmax(600px, 1fr);
  gap: var(--sds-space-padding-small) var(--sds-space-padding-medium);
  grid-auto-columns: auto;
}

@media (width <= 1800px) {
  .containerGrid {
    grid-template-columns: minmax(400px, 480px) minmax(600px, 3fr);
  }

  .rightContainer {
    grid-column: 2;
    grid-row: 2;
  }
}

@media (width <= 1070px) {
  .containerGrid {
    grid-template-columns: 1fr;
  }

  .rightContainer {
    grid-column: 1;
    grid-row: 3;
  }
}

.cannotEditMessage {
  width: 100%;
  height: max-content;
  background-color: var(--sds-color-support-warning-subtle);
  padding: 1rem;
  margin: 0 0 1rem;
}
