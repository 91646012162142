.title {
  padding-bottom: var(--sds-space-padding-large);
}

.tableContainer {
  background-color: var(--sds-color-layout-background-default);
  border-radius: var(--sds-space-border-radius-medium);
  padding: var(--sds-space-padding-medium);
}

.cellMaxWidth {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topBarContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.searchContainer {
  max-width: 400px;
  margin: 0 0 var(--sds-space-padding-medium) var(--sds-space-padding-medium);
  flex-grow: 1;
}

.menuContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.filterContainer {
  margin: 0 0 var(--sds-space-padding-medium) var(--sds-space-padding-medium);
}

.filterToggle:not(:last-child) {
  margin: 0 var(--sds-space-padding-tiny) var(--sds-space-padding-tiny) 0;
}

.toggleDisabled {
  pointer-events: none;
  opacity: 0.5;
}
